<template>
	<div></div>
</template>

<script>
export default {
	name: 'portauthority-modals',
	data() {
		return {};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {}
};
</script>
